import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import If from "utils/If";
import {
  ArticleContainer,
  Article,
  Title,
  Author,
  Journal,
  Conference,
} from "./Bib";

const ArticleTemplate = ({
  id,
  author,
  showAuthor = false,
  title,
  date,
  journal,
  conference,
  url,
}) => (
  <Article key={id}>
    <Title href={url}>{title}</Title>

    {/* Display author? */}
    <If condition={showAuthor} then={<Author>{author}</Author>} />

    {/* Journal or conference? */}
    <If
      condition={journal}
      then={
        <Journal>
          {journal}, {date}
        </Journal>
      }
      else={
        <Conference>
          {conference}, {date}
        </Conference>
      }
    />
  </Article>
);

const Articles = ({
  data: {
    allGoogleSpreadsheetArticles: { articles },
  },
  showAuthor = true,
  selectedPublications = false,
}) =>
  articles.reduce((articles, article) => {
    if (!selectedPublications) {
      return [...articles, ArticleTemplate({ ...article, showAuthor })];
    }

    if (article.selected === "TRUE") {
      return [...articles, ArticleTemplate({ ...article, showAuthor })];
    }

    return articles;
  }, []);

export const articlesFragment = graphql`
  fragment ArticleFields on GoogleSpreadsheetArticles {
    id
    author
    title
    date
    journal
    conference
    url
    selected
  }
`;

export default ({ selected, author }) => {
  const data = useStaticQuery(graphql`
    {
      allGoogleSpreadsheetArticles(sort: { fields: date, order: DESC }) {
        articles: nodes {
          ...ArticleFields
        }
      }
    }
  `);

  return (
    <ArticleContainer>
      <Articles
        data={data}
        selectedPublications={selected}
        showAuthor={author}
      />
    </ArticleContainer>
  );
};
