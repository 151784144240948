import React from "react";
import { graphql } from "gatsby";
import theme from "gatsby-theme-googlesheets-personal-web/src/theme";
import Layout from "gatsby-theme-googlesheets-personal-web/src/components/Layout";
import Flex from "gatsby-theme-googlesheets-personal-web/src/components/Flex";
import Card from "gatsby-theme-googlesheets-personal-web/src/components/Card";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Articles from "components/publications/Articles";

export default ({ data }) => {
  const { photo, about } = data;

  return (
    <Layout>
      <Flex direction="column" grow={1} halign="stretch">
        <Card
          image={{
            fixed: photo.childImageSharp.fixed,
            height: photo.childImageSharp.fixed.height,
            alt: "Mario Čagalj",
          }}
          content={{
            title: "Mario Čagalj",
            subtitle: "professor",
            affiliation: {
              name: "University of Split, FESB",
              url: "https://www.fesb.hr",
            },
            scholar: {
              url: "https://scholar.google.hr/citations?user=y3F7cwIAAAAJ",
              text: "Google Scholar",
            },
          }}
        />
        <MDXRenderer>{about.body}</MDXRenderer>
        <h2 style={theme.markdown.h2}>Selected publications</h2>
        <Articles selected={true} author={false} />
      </Flex>
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery {
    photo: file(relativePath: { eq: "mcagalj.jpg" }) {
      childImageSharp {
        fixed(base64Width: 100, width: 100, quality: 100) {
          base64
          height
        }
      }
    }

    about: mdx(frontmatter: { path: { eq: "/" } }) {
      body
    }
  }
`;
