import styled from "@emotion/styled";
import theme from "gatsby-theme-googlesheets-personal-web/src/theme";

const ArticleContainer = styled.div`
  counter-reset: index-counter;
`;

const Article = styled.div`
  margin-top: 40px;
  position: relative;
  padding-left: 34px;
  &:before {
    content: counter(index-counter);
    counter-increment: index-counter;
    width: 24px;
    position: absolute;
    left: 0px;
    top: 0px;
    text-align: right;
  }
`;

const Title = styled.a``;

const P = styled.p`
  color: ${theme.colors.primary};
  @media (min-width: ${theme.media.small}px) {
    font-size: 16px;
    line-height: 1.4;
  }
`;

const Author = styled(P)`
  font-style: italic;
`;

const Journal = styled(P)``;

const Conference = styled(P)``;

const Institution = styled(P)``;

export {
  ArticleContainer,
  Article,
  Title,
  Author,
  Journal,
  Conference,
  Institution,
};
