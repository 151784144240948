import React from "react";
import PropTypes from "prop-types";
import Flex from "./Flex";
import styled from "@emotion/styled";

const CardWrapper = styled(Flex)``;

const CardContent = styled(Flex)`
  padding-left: 10px;
  line-height: 1.4;
  height: ${(props) => props.height}px;
  font-size: 16px;
  * {
    white-space: nowrap;
  }
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
`;

const Subtitle = styled.p``;

const Card = ({ image, content }) => {
  const { title, subtitle, affiliation, scholar } = content || {};

  return (
    <CardWrapper>
      <img src={image.fixed.base64} alt={image.alt} />
      <CardContent
        direction="column"
        valign="space-between"
        height={image.height}
      >
        <Flex direction="column">
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Flex>
        <Flex direction="column">
          <a href={affiliation.url}>{affiliation.name}</a>
          <a href={scholar.url}>{scholar.text}</a>
        </Flex>
      </CardContent>
    </CardWrapper>
  );
};

export default Card;

Card.propTypes = {
  image: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
};
